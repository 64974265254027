import React, { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { PropsWithTestId } from '../../../../../../types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import {
  SpeakerDisplayStyleTypes,
  SpeakerSectionDisplayTypes,
  SpeakersModuleType,
} from './types';
import { ModuleHeader } from '../../components';
import { CallToAction } from '../../../CallToActionGroup';
import SpeakersByEventDisplayStyle from './SpeakersByEventDisplayStyle';
import SpeakersTogetherDisplayStyle from './SpeakersTogetherDisplayStyle';
import s from './index.module.scss';
import { HubSpeakersDiscoveryBlock } from '../../../../../../store/features/discovery';

export interface SpeakersModuleProps {
  module: SpeakersModuleType;
}

export default function SpeakersModule(
  props: PropsWithTestId<SpeakersModuleProps>,
) {
  const {
    module: {
      '@type': type,
      title,
      titleSize,
      subtitle,
      visualStyle,
      displayStyle,
      elementId,
      speakersFromDiscovery = [],
      speakers = [],
      link,
    },
    testId,
  } = props;

  const sortedSpeakers = useMemo(() => {
    return speakers.reduce((acc, speakerBlock, index) => {
      if (speakerBlock.displayType === SpeakerSectionDisplayTypes.BY_TYPE) {
        const sortedSpeakerBlock = {
          ...speakersFromDiscovery[index],
          speakers: orderBy(
            speakersFromDiscovery[index].speakers,
            ['title'],
            ['asc'],
          ),
        };

        return [...acc, sortedSpeakerBlock];
      }

      return [...acc, speakersFromDiscovery[index]];
    }, [] as HubSpeakersDiscoveryBlock[]);
  }, [speakersFromDiscovery, speakers]);

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <ModuleHeader
              title={title}
              titleSize={titleSize}
              text={subtitle}
              lineAfterHeading={!!subtitle}
              className={s.headerOffset}
            />
          </div>
        </div>
        {displayStyle === SpeakerDisplayStyleTypes.BY_EVENT && (
          <SpeakersByEventDisplayStyle speakersFromDiscovery={sortedSpeakers} />
        )}
        {displayStyle === SpeakerDisplayStyleTypes.TOGETHER && (
          <SpeakersTogetherDisplayStyle
            speakersFromDiscovery={sortedSpeakers}
          />
        )}
        <div className="row">
          <div className="col-xs-12">
            {link?.type && (
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                data-testid="hub-module-link"
              />
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

SpeakersModule.defaultProps = {
  testId: 'hub-speakers-module',
};
